<div class="container-fluid visit-store" *ngIf="config.title !== 'Aiema'">
    <ul>
        <li>
            <h3 >Visit our latest products</h3>
        </li>
        <li>
            <a class="btn btn-secondary" (click)="getLatestProducts()" >Know More &nbsp; <i
                    class="fa-angle-right fa"></i></a>
        </li>
    </ul>
</div>
<div class="container-fluid visit-store" *ngIf="config.title === 'Aiema'" style="background-image: linear-gradient(to right top, #051937, #004d7a, #008793, #37b4b0, #33e4de);font-family: 'proxima-regular','sans-serif'!important;">
    <ul style="margin-bottom:0px !important">
        <li>
            <h3>Explore AIEMA</h3>
        </li>
        <li>
            <a class="btn btn-secondary" (click)="getLatestProducts()" >Know More &nbsp; <i
                    class="fa-angle-right fa"></i></a>
        </li>
    </ul>
</div>
<footer class="container-fluid footer" *ngIf="config.title !== 'Aiema' && config.title !== 'BombayHardware'&& config.title !== 'Beaubelle'">
    <div class="container">
        <div class="footer-links footer-row-one">
            <div class="footer-col">
                <h4 class="sm-bold" style="color: #212529;">ONLINE SHOPPING</h4>
                <ul >
                    <li *ngFor="let online of onLineShopping;" (click)="navigateToProduct(online.link)"><a style="color: #375673 !important;font-weight:450"
                            class="custom-point">{{online.name}}</a></li>
                </ul>
            </div>
            <div class="footer-col">
                <h4 class="sm-bold" style="color: #212529;">QUICK LINKS</h4>
                <ul>
                    <li><a style="color: #375673 !important;font-weight:450" routerLink="/">Home</a></li>
                    <li><a style="color: #375673 !important;font-weight:450" routerLink="about-us">About Us</a></li>
                    <li><a style="color: #375673 !important;font-weight:450" routerLink="contact-us">Contact Us</a></li>
                    <!-- <li (click)="getBrochure()"><a >Brochure</a></li> -->
                </ul>
            </div>
            <div class="footer-col">
                <h4 class="sm-bold" style="color: #212529;">USEFUL LINKS</h4>
                <ul>
                    <!-- <li><a style="color: #375673 !important;font-weight:450" routerLink="about-us">Contact Us</a></li> -->
                    <!-- <li><a style="color: #375673 !important;font-weight:450" routerLink="/home">FAQ</a></li> -->
                    <li><a style="color: #375673 !important;font-weight:450" routerLink="/terms-and-condition">Terms & Conditions</a></li>
                    <li><a style="color: #375673 !important;font-weight:450" routerLink="/privacy-policy">Privacy Policy</a></li>
                    <li><a style="color: #375673 !important;font-weight:450" routerLink="/cancellation-policy">Cancellation Policy</a></li>
                    <li><a style="color: #375673 !important;font-weight:450" routerLink="/shipping-and-delivery">Shipping & Delivery</a></li>
                    <li><a style="color: #375673 !important;font-weight:450" routerLink="/return-policy">Return & Exchanges</a></li>
                </ul>
            </div>
            <div class="footer-col contact-section address">
                <h4 class="sm-bold" style="color: #212529;">CONTACT US</h4>
                <p style="color: #375673 !important;"><i class="fa-map-marker fa"></i>
                    Green Cotton Company<br>
                    No. 8, BM – 441,<br>
                    1st Block HRBR Layout,<br>
                    Bangalore – 560043,<br>
                    Karnataka.
                </p>
                <p><i class="fa-phone  fa"></i>
                    <a style="color: #375673 !important;font-weight:450">+91 8884500900</a>
                </p>
                <p><i class="fa-globe  fa"></i>
                    <a style="color: #375673 !important;font-weight:450" href="enquiry@earthyworthy.in" target="_blank">enquiry@earthyworthy.in</a>
                </p>
            </div>
        </div>
    </div>
</footer>
<footer class="container-fluid footer" *ngIf="config.title === 'Beaubelle'">
    <div class="container">
        <div class="footer-links footer-row-one">
            <div class="footer-col">
                <h4 class="sm-bold" style="color: #212529;">ONLINE SHOPPING</h4>
                <ul >
                    <li *ngFor="let online of onLineShopping;" (click)="navigateToCategory(online.name)"><a style="color: #375673 !important;font-weight:450"
                            class="custom-point">{{online.name}}</a></li>
                </ul>
            </div>
            <div class="footer-col">
                <h4 class="sm-bold" style="color: #212529;">QUICK LINKS</h4>
                <ul>
                    <li><a style="color: #375673 !important;font-weight:450" routerLink="/">Home</a></li>
                    <li><a style="color: #375673 !important;font-weight:450" routerLink="about-us">About Us</a></li>
                    <li><a style="color: #375673 !important;font-weight:450" routerLink="contact-us">Contact Us</a></li>
                    <!-- <li (click)="getBrochure()"><a >Brochure</a></li> -->
                </ul>
            </div>
            <div class="footer-col">
                <h4 class="sm-bold" style="color: #212529;">USEFUL LINKS</h4>
                <ul>
                    <!-- <li><a style="color: #375673 !important;font-weight:450" routerLink="about-us">Contact Us</a></li> -->
                    <!-- <li><a style="color: #375673 !important;font-weight:450" routerLink="/home">FAQ</a></li> -->
                    <li><a style="color: #375673 !important;font-weight:450" routerLink="/terms-and-condition">Terms & Conditions</a></li>
                    <li><a style="color: #375673 !important;font-weight:450" routerLink="/privacy-policy">Privacy Policy</a></li>
                    <li><a style="color: #375673 !important;font-weight:450" routerLink="/cancellation-policy">Cancellation Policy</a></li>
                    <li><a style="color: #375673 !important;font-weight:450" routerLink="/shipping-and-delivery">Shipping & Delivery</a></li>
                    <li><a style="color: #375673 !important;font-weight:450" routerLink="/return-policy">Return & Exchanges</a></li>
                </ul>
            </div>
            <div class="footer-col contact-section address">
                <h4 class="sm-bold" style="color: #212529;">CONTACT US</h4>
                <p style="color: #375673 !important;"><i class="fa-map-marker fa"></i>
                    Beaubelle Products & Care Private Limited<br>
                    53/76, Venkatachalam Street,<br>
                    Royapuram,<br>
                    Chennai 600013,<br>
                    Tamil Nadu.
                </p>
                <p><i class="fa-phone  fa"></i>
                    <a style="color: #375673 !important;font-weight:450">+91 9381857000</a>
                </p>
                <p><i class="fa-globe  fa"></i>
                    <a style="color: #375673 !important;font-weight:450" href="vilva@beaubelle.in" target="_blank">vilva@beaubelle.in</a>
                </p>
            </div>
        </div>
    </div>
</footer>
<footer class="container-fluid footer" *ngIf="config.title === 'Aiema'">
    <div class="container" style="font-family: 'proxima-regular','sans-serif'!important;">
        <div class="footer-links footer-row-one" >
            <div class="footer-col">
                <h4 class="sm-bold" style="color: #212529; font-weight: 650;">ONLINE ENQUIRY</h4>
                <ul >
                    <li *ngFor="let online of onLineShopping;" (click)="navigateToProduct(online.link)"><a style="color: #375673 !important;font-weight:450"
                            class="custom-point">{{online.name}}</a></li>
                </ul>
            </div>
            <div class="footer-col">
                <h4 class="sm-bold" style="color: #212529;  font-weight: 650;">QUICK LINKS</h4>
                <ul>
                    <li><a style="color: #375673 !important;font-weight:450" (click)='scrollFunction()' routerLink="/">Home</a></li>
                    <li><a style="color: #375673 !important;font-weight:450" (click)='scrollFunction()' routerLink="about-us">About Us</a></li>
                    <li><a style="color: #375673 !important;font-weight:450" (click)='scrollFunction()' routerLink="contact-us">Contact Us</a></li>
                    <!-- <li (click)="getBrochure()"><a >Brochure</a></li> -->
                </ul>
            </div>
            <div class="footer-col">
                <h4 class="sm-bold" style="color: #212529;  font-weight: 650;">USEFUL LINKS</h4>
                <ul>
                    <li><a style="color: #375673 !important;font-weight:450" (click)='scrollFunction()' routerLink="/terms-and-condition">Terms & Conditions</a></li>
                    <li><a style="color: #375673 !important;font-weight:450" (click)='scrollFunction()' routerLink="/privacy-policy">Privacy Policy</a></li>
                </ul>
            </div>
            <div class="footer-col contact-section address">
                <h4 class="sm-bold" style="color: #212529;  font-weight: 650;">CONTACT US</h4>
                <p *ngIf="config.title === 'Aiema'" style="color: #375673 !important;"><i class="fa-map-marker fa"></i>
                    Ambattur Industrial Estate Manufacturers’ Association,<br>
                    ATC Road,<br>
                    Ambattur Industrial Estate,<br>
                    Chennai – 600058,<br>
                    Tamilnadu.
                </p>
                <p><i class="fa-phone  fa"></i>
                    <a style="color: #375673 !important;font-weight:450">+91 8939628603</a>
                </p>
                <p><i class="fa-envelope  fa"></i>
                    <a style="color: #375673 !important;font-weight:450" href="mailto: mail@aiema.net">mail@aiema.net</a>
                </p>
            </div>
        </div>
    </div>
</footer>

<footer class="container-fluid footer" *ngIf="config.title === 'BombayHardware'">
    <div class="container" style="font-family: 'proxima-regular','sans-serif'!important;">
        <div class="footer-links footer-row-one">
            <div class="footer-col">
                <h4 class="sm-bold" style="color: #212529;">ONLINE ENQUIRY</h4>
                <ul >
                    <li *ngFor="let online of onLineShopping;" (click)="navigateToProduct(online.link)"><a style="color: #375673 !important;font-weight:450"
                            class="custom-point">{{online.name}}</a></li>
                </ul>
            </div>
            <div class="footer-col">
                <h4 class="sm-bold" style="color: #212529;">QUICK LINKS</h4>
                <ul>
                    <li><a style="color: #375673 !important;font-weight:450" (click)='scrollFunction()' routerLink="/">Home</a></li>
                    <li><a style="color: #375673 !important;font-weight:450" (click)='scrollFunction()' routerLink="about-us">About Us</a></li>
                    <li><a style="color: #375673 !important;font-weight:450" (click)='scrollFunction()' routerLink="contact-us">Contact Us</a></li>
                    <!-- <li (click)="getBrochure()"><a >Brochure</a></li> -->
                </ul>
            </div>
            <div class="footer-col">
                <h4 class="sm-bold" style="color: #212529;">USEFUL LINKS</h4>
                <ul>
                    <li><a style="color: #375673 !important;font-weight:450" (click)='scrollFunction()' routerLink="/terms-and-condition">Terms & Conditions</a></li>
                    <li><a style="color: #375673 !important;font-weight:450" (click)='scrollFunction()' routerLink="/privacy-policy">Privacy Policy</a></li>
                </ul>
            </div>
            <div class="footer-col contact-section address">
                <h4 class="sm-bold" style="color: #212529;">CONTACT US</h4>
                <p style="color: #375673 !important;"><i class="fa-map-marker fa"></i>
                    Bombay Hardware Private Limited<br>
                    No. 32, Sembudoss Street, 1st Floor, Parrys,<br>
                    Corner Estate, George Town, <br>
                    Chennai - 600001,<br>
                    Tamil Nadu, India
                </p>
                <p><i class="fa-phone  fa"></i>
                    <a style="color: #375673 !important;font-weight:450">08048976462</a>
                </p>
                <p><i class="fa-envelope  fa"></i>
                    <a style="color: #375673 !important;font-weight:450" href=""></a>
                </p>
            </div>
        </div>
    </div>
</footer>

<footer>
    <div class="footer-row-two">
        <div class="s-icons">
            <ul class="social-icons">
                <li>
                    <a href="#">
                        <i class="fa fa-facebook"></i>
                    </a>
                </li>
                <li>
                    <a href="#">
                        <i class="fa fa-twitter"></i>
                    </a>
                </li>
                <li>
                    <a href="#">
                        <i class="fa fa-linkedin"></i>
                    </a>
                </li>
                <li>
                    <a href="#">
                        <i class="fa fa-google-plus" aria-hidden="true"></i>
                    </a>
                </li>
                <li>
                    <a href="#">
                        <i class="fa fa-pinterest" aria-hidden="true"></i>
                    </a>
                </li>

            </ul>
        </div>
        <div *ngIf="copyRightText && copyRightText !== ''">
            <div class="copy">
                © {{copyRightText}}
            </div>
        </div>
    </div>
</footer>